.statistic {

    &__heading {
        margin-top: 20vh;
        text-align: center;

        @include media-breakpoint-down(md) {
            margin-top: 15vh;
        }

        h4 {
            font-size: 16px;
            font-family: $font-family-medium;
            font-weight: $font-weight-medium;
            text-transform: uppercase;
            line-height: 1;

            color: rgba($color-white, .75);

            margin-bottom: 10px;
        }

        h3 {
            @include make-responsive(font-size, $whoarewe-font-size);
            font-family: $font-family-medium;
            font-weight: $font-weight-medium;
            text-transform: uppercase;

            color: $color-white;
        }
    }

    &__numbers {
        margin-top: 10vh;
        background-color: $color-white;

        position: relative;
        width: 100%;
        overflow: hidden;

        padding-top: 4vh;
        padding-bottom: 4vh;

        .scroll{
            width: 100%;
            display: flex;

            &.left {
                div {
                    animation-direction: reverse;

                    &:nth-child(2) {
                        animation-direction: reverse;
                    }
                }
            }
        }

        .scroll div{
            @include make-responsive(font-size, $statistic-font-size);
            font-family: $font-family-bold;
            font-weight: $font-weight-bold;
            line-height: 1;

            color: $color-statistic-numbers;

            text-transform: uppercase;
            display: flex;
            min-width: auto;
            white-space: nowrap;
            will-change: transform;

            animation: animate 40s linear infinite;
            animation-delay: -20s;

            span {
                font-size: 21px;
                font-family: $font-family-medium;
                font-weight: $font-weight-medium;

                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                }

                &.pos-bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                }
            }
        }

        .scroll div:nth-child(2){
            animation: animate2 40s linear infinite;
            animation-delay: -40s;
        }
    }
}