.is-hidden {
    display: none !important;
}

strong {
    font-family: $font-family-black;
}

.mobile-hidden {
    @include media-breakpoint-down(md) {
        opacity: 0;
        display: none !important;
    }
}