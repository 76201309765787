$body-padding-left: (
    xs: 15px,
    sm: 20px,
    md: 7vw
);

$body-padding-right: (
    xs: 15px,
    sm: 20px,
    md: 7vw
);

$explore-padding-top: (
    xs: 150px,
    lg: 200px
);

$h1-font-size: (
    xs: 11.5vw,
    md: 11.5vw,
    lg: 11vw
);

$footer-headline-font-size: (
    xs: 7vw,
    md: 7vw,
    lg: 7vw
);

$whoarewe-font-size: (
    xs: 5vw,
    md: 3.5vw,
    lg: 2.8vw
);

$opener-a-font-size: (
    xs: 3vw,
    md: 2.5vw,
    lg: 2vw
);

$statistic-font-size: (
    xs: 18vw,
    md: 15.5vw,
    lg: 20vw
);

$Power2EaseOut: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easing: $Power2EaseOut;