.footer {
    padding-top: 15vh;

    &__inner {
        @include make-responsive(padding-left, $body-padding-left);
        @include make-responsive(padding-right, $body-padding-right);
        padding-top: 8vh;
        padding-bottom: 4vh;

        background-color: $color-white;

        @include media-breakpoint-down(sm) {
            padding-top: 6vh;
            padding-bottom: 1vh;
        }

        > h4 {
            font-size: 21px;
            font-family: $font-family-medium;
            font-weight: $font-weight-medium;

            color: rgba($color-black, .39);
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
        }

        > a {
            @include transition;
            display: flex;
            align-items: center;
            flex: 0 0 auto;


            img {
                @include transition;
                margin-left: 2vw;
                display: flex;
                flex: 0 0 auto;

                fill: $color-black;

                width: 12vw;
                height: 16vh;

                transform: translate3d(0px, 0px, 0px);
                transform-style: preserve-3d;
                will-change: transform;

                @include media-breakpoint-down(lg) {
                    transform:scale(0.7);
                    margin-left: 0;
                    max-width: 180px;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }

            &::after {
                content: '';

                width: 100%;
                height: 2px;

                background-color: rgba($color-black, .32);

                margin-left: 3vw;

                @include media-breakpoint-down(md) {
                    margin-left: 2vw;
                }
            }

            &:hover {
                text-decoration: none;

                h3 {
                    color: $color-hover-primary;
                }

                svg {
                    transform: translate3d(20px, -20px, 0px);
                }
            }
        }

        h3 {
            @include transition;
            @include make-responsive(font-size, $footer-headline-font-size);
            font-family: $font-family-bold;
            font-weight: $font-weight-bold;
            line-height: 1.1;

            color: $color-black;

            margin-left: -0.5vw;

            display: flex;
            flex: 0 0 auto;
        }
    }

    &__copyright {
        padding-top: 8vh;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0;

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, 1fr);
            padding-top: 5vh;
        }

        &__item {
            @include media-breakpoint-down(md) {
                text-align: center;
                margin-bottom: 1.5vh;
            }

            p,
            a {
                font-size: 13px;
                font-family: $font-family-medium;
                font-weight: $font-weight-medium;
                text-transform: uppercase;
                line-height: 1;

                color: rgba($color-black, .32);
            }

            &:nth-child(2) {
                text-align: center;
            }

            &:nth-child(3) {
                text-align: right;

                @include media-breakpoint-down(md) {
                    text-align: center;
                }

                a {
                    color: $color-black;

                    &:not(:last-of-type) {
                        margin-right: 15px;
                    }
                }
            }

            a {
                @include transition;

                &:hover {
                    text-decoration: none;
                    color: $color-hover-primary;
                }
            }
        }

    }
}