.whoarewe {
    @include make-responsive(padding-left, $body-padding-left);
    margin-top: 20vh;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    overflow: hidden;

    @include media-breakpoint-down(md) {
        @include make-responsive(padding-right, $body-padding-left);
        margin-top: 15vh;
        flex-direction: column;
    }

    &__textblock {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex: 0 0 auto;
        width: 45%;

        padding-right: 35px;

        @include media-breakpoint-down(md) {
            width: 100%;
            padding-right: 0;

            padding-bottom: 50px;
        }

        h4 {
            font-size: 16px;
            font-family: $font-family-medium;
            font-weight: $font-weight-medium;
            text-transform: uppercase;
            line-height: 1;

            color: rgba($color-white, .75);

            margin-bottom: 30px;
            width: 100%;
        }

        h2 {
            @include make-responsive(font-size, $whoarewe-font-size);
            font-family: $font-family-medium;
            font-weight: $font-weight-medium;

            color: $color-white;
        }
    }

    &__image {
        width: 60vw;
        max-height: 50vh;

        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;

        overflow: hidden;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;

            vertical-align: middle;
            display: inline-block;
        }
    }
}