/**
 * Colors
 */

$color-white: #fff;
$color-black: #000;

$color-bg-color-whoarewe: #f22161;
$color-bg-color: #1f1f1ff2;

$color-nav-hover: #f22161;
$color-opener-a-hover: #f22161;
$color-hover-primary: #f22161;

$color-nav-background-mobile: #f22161;
$color-button-bg: #f22161;
$color-button-bg-inverted: #1f1f1f;

$color-statistic-numbers: #1f1f1f;