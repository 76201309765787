/**
 * Headings
 */

 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 .h1,
 .h2,
 .h3,
 .h4,
 .h5,
 .h6 {
   margin: 0;
   font-family: $headings-font-family;
   font-weight: $headings-font-weight;
   letter-spacing: $headings-letter-spacing;
   line-height: $headings-line-height;
   color: $headings-color;
   text-transform: $headings-text-transform;
 }

 h1,
 .h1 {
  @include make-responsive(font-size, $h1-font-size);
  font-family: $font-family-medium;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  line-height: 1.1;

  @include media-breakpoint-only(sm) {
    line-height: 1.3;
  }

  @include media-breakpoint-down(xs) {
    line-height: 1.5;
  }
}