.opener {
    @include make-responsive(padding-top, $explore-padding-top);
    @include make-responsive(padding-left, $body-padding-left);
    @include make-responsive(padding-right, $body-padding-right);

    position: relative;

    &__divider {
        width: 100%;
        height: 100px;
        max-width: 466px;

        position: absolute;
        @include make-responsive(right, $body-padding-right);
        @include make-responsive(top, $explore-padding-top);

        @include media-breakpoint-down(sm) {
            width: calc(100% - (20px * 2));
        }

        @include media-breakpoint-down(xs) {
            width: calc(100% - (15px * 2));
        }

        &.is-inview {
            &::before{
                transform: scaleX(1);
            }

            &::after {
                transform: scaleY(1);
            }
        }

        &::before,
        &::after {
            position: relative;
            top: -50px;
        }

        &::before {
            content: '';
            width: 100%;
            height: 2px;
            background-color: $color-black;
            display: block;


            transform: scaleX(0);
            transform-origin: center left;
            transition: transform 1s $easing;
            transition-delay: 1s;
        }

        &::after {
            content: '';
            width: 2px;
            height: 100%;
            background-color: $color-black;
            display: block;

            transform: scaleY(0);
            transform-origin: center left;
            transition: transform 1s $easing;
            transition-delay: 1s;

            float: right;
        }
    }

    h1 {
        margin-left: -0.7vw;
    }

    a {
        @include make-responsive(font-size, $opener-a-font-size);
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        line-height: 1;

        color: $color-white;

        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;

        margin-top: 70px;

        &::after {
            @include transition;
            content: '';
            display: block;
            height: 5px;
            width: 100%;
            margin-top: 5px;

            background: linear-gradient(to left, $color-white 50%, $color-opener-a-hover 50%) right;
            background-size: 200%;
        }

        svg {
            @include transition;
            margin-left: 15px;
        }

        &:hover {
            text-decoration: none;

            &::after {
                background-position: left;
            }

            svg {
                fill: $color-opener-a-hover;
            }
        }
    }

    .o-title {
        perspective: 600px;
        -webkit-perspective: 600px;

        &_line {
            display: block;
            opacity: 0;
            transform-origin: center top;
            transform-style: preserve-3d;
            transform: translateY(100%) rotateX(-80deg);
            transition: opacity 0.8s $Power2EaseOut, transform 0.8s $Power2EaseOut;
            font-size: 0;


            span {
                display: inline-block;
                min-width: 0.05em;
                @include make-responsive(font-size, $h1-font-size);
                font-family: $font-family-medium;
                font-weight: $font-weight-medium;
                text-transform: uppercase;
                line-height: 1.1;
            }
        }

        &.is-inview {

            .o-title_line {
                transform: none;
                opacity: 1;

                @for $i from 1 through 6 {
                    &:nth-child(#{$i}){
                        transition-delay: 0.3 + $i*0.1s;
                    }
                }
            }
        }
    }
}