/**
 * Typography
 */

 // Font Family
$font-family-regular: "Metropolis Regular", Arial, sans-serif, !default;
$font-family-medium: "Metropolis Medium", Arial, sans-serif, !default;
$font-family-bold: "Metropolis Bold", Arial, sans-serif, !default;
$font-family-black: "Metropolis Black", Arial, sans-serif, !default;
$font-family-headings: $font-family-black;

// Font Weight
$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-black: 800 !default;

// Line Height
$line-height-none: 1 !default;
$line-height-base: 24px;


$headings-font-family: $font-family-headings !default;
$headings-font-weight: $font-weight-medium !default;
$headings-letter-spacing: normal !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;
$headings-text-transform: inherit !default;