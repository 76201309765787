.services {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-top: 20vh;

    @include media-breakpoint-only(lg) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @include media-breakpoint-down(md) {
        margin-top: 15vh;
    }

    .scroll{
        width: 100%;
        display: flex;

        @include media-breakpoint-down(xs) {
            margin-left: -150px;
            z-index: 999;
        }
    }

    .scroll div{
        @include make-responsive(font-size, $whoarewe-font-size);
        font-family: $font-family-medium;
        font-weight: $font-weight-medium;
        line-height: 1.2;
        padding: 0 .05em;


        color: rgba($color-white, .75);

        text-transform: uppercase;
        display: inline-block;
        min-width: auto;
        white-space: nowrap;
        // will-change: transform;

        animation: animate 40s linear infinite;
        animation-delay: -40s;

        @include media-breakpoint-down(xs) {
            animation: animate 30s linear infinite;
            animation-delay: -30s;
            font-family: Arial !important;
        }
    }

    .scroll div:nth-child(2){
        animation: animate2 40s linear infinite;
        animation-delay: -20s;

        @include media-breakpoint-down(xs) {
            animation: animate2 30s linear infinite;
            animation-delay: -15s;
        }
    }

    &__heading {
        margin-top: 20vh;
        text-align: center;

        @include media-breakpoint-down(md) {
            margin-top: 15vh;
        }

        h4 {
            font-size: 16px;
            font-family: $font-family-medium;
            font-weight: $font-weight-medium;
            text-transform: uppercase;
            line-height: 1;

            color: rgba($color-white, .75);

            margin-bottom: 10px;
        }

        h3 {
            @include make-responsive(font-size, $whoarewe-font-size);
            font-family: $font-family-medium;
            font-weight: $font-weight-medium;
            text-transform: uppercase;

            color: $color-white;
        }
    }
    .features {
        padding: 1rem;

        &__box {
            max-width: 1280px;
            margin: 100px auto -100px;
            list-style: none;
            padding: 1rem;

            &-copy {
                img {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }

            &-item {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 150px;
                align-items: center;

                padding-bottom: 150px;

                @include media-breakpoint-down(md) {
                    grid-template-columns: repeat(1, 1fr);
                    text-align: center;
                    grid-gap: 50px;
                    padding-bottom: 100px;
                }

                h3 {
                    @include make-responsive(font-size, $whoarewe-font-size);
                    // @include make-responsive(padding-right, $body-padding-right);

                    font-family: $font-family-medium;
                    font-weight: $font-weight-medium;

                    color: $color-white;

                    display: flex;
                    flex: 0 0 auto;

                    // min-width: 35vw;
                    margin-bottom: 25px;

                    @include media-breakpoint-down(md) {
                        text-align: center;
                        display: block;
                    }
                }

                p {
                    font-size: 18px;
                    font-family: $font-family-medium;
                    font-weight: $font-weight-medium;
                    line-height: 1.2;

                    color: $color-white;

                    @include media-breakpoint-up(lg) {
                        max-width: 50vw;
                    }

                    @include media-breakpoint-down(md) {
                        margin-top: 16px;
                        max-width: 100%;
                    }
                }

                img {
                    max-width: 75%;
                    margin: 0 auto;

                    @include media-breakpoint-down(md) {
                        margin: 0 auto;
                        max-width: 50%;
                    }
                }
            }
        }
    }

    &__more {
        @include make-responsive(padding-left, $body-padding-left);
        @include make-responsive(padding-right, $body-padding-right);
        padding-top: 4vh;

        p {
            font-size: 21px;
            font-family: $font-family-medium;
            font-weight: $font-weight-medium;
            line-height: 1;

            color: rgba($color-white, .75);

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
        }
    }
}

.box-order-1 {
    @include media-breakpoint-down(md) {
        order: 1
    }
}

.box-order-2 {
    @include media-breakpoint-down(md) {
        order: 2
    }
}