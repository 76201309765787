@keyframes animate{
    0%{
      transform: translateX(100%);
    }
    100%{
      transform: translateX(-100%);
    }
}

@keyframes animate2{
    0%{
      transform: translateX(0);
    }
    100%{
      transform: translateX(-200%);
    }
}

@-webkit-keyframes animate {
  0%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(-100%);
  }
}

@-webkit-keyframes animate2 {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(-200%);
  }
}
