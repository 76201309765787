.header {
	position: fixed;
	z-index: 999;
	width: 100%;
	padding: 20px 0;
	width: 100%;

	background-color: transparent;

	perspective: 600px;
    -webkit-perspective: 600px;

    transition: opacity 1s $Power2EaseOut, transform 1s $Power2EaseOut;
	opacity: 0;


    .has-scroll-init & {
        transform: none;
		opacity: 1;
    }


	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include make-responsive(padding-left, $body-padding-left);
		@include make-responsive(padding-right, $body-padding-right);
	}

	&__logo {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		> img {
			width: 60px;
			height: 58px;
		}

		> span {
			font-family: $font-family-bold;
			font-weight: $font-weight-bold;
			font-size: 16px;
			line-height: 1;

			color: $color-black;
			margin-left: 15px;
		}
	}

	&__nav {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		@include media-breakpoint-down(md) {
			@include transition;

			align-items: center;
			display: flex;
			flex-flow: column;
			justify-content: center;

			width: 100%;
			height: 100vh;

			position: fixed;
			top: 0;
			left: 0;
			right: 0;

			background-color: $color-nav-background-mobile;
			text-align: center;

			padding: 0;
			margin: 0 auto;
		}

		> a {
			font-family: $font-family-medium;
			font-weight: $font-weight-medium;
			font-size: 16px;
			line-height: 1;

			color: $color-black;
			text-transform: uppercase;

			position: relative;
			overflow: hidden;

			@include media-breakpoint-down(md) {
				font-size: 38px;

				&:not(:last-of-type) {
					margin-bottom: 10vh;
				}
			}

			span {
				position: absolute;
				top: 0;
				left: 0;
				overflow: hidden;
				transform: translateX(-100%);
				transition: transform 275ms ease;

				&::before {
					display: inline-block;
					content: attr(data-content);
					color:  $color-white;
					transform: translateX(100%);
					transition: transform 275ms ease;

					.opener-is-visible & {
						color: $color-white !important;
					}
				}
			}

			&:hover {
				text-decoration: none;

				span {
					transform: translateX(0);

					&::before {
						transform: translateX(0);
					}
				}
			}
		}

		> span {

			font-family: $font-family-regular;
			font-weight: $font-weight-regular;
			font-size: 16px;
			line-height: 1;

			color: $color-black;
			margin: 0 2vw;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	&__burger {
		z-index: 9999;
		background-color: $color-white;

		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	.ham {
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;
		transition: transform 400ms;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.hamRotate.active {
		transform: rotate(45deg);
	}

	.hamRotate180.active {
		transform: rotate(180deg);
	}

	.line {
		fill:none;
		transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
		stroke:#000;
		stroke-width:5;
		stroke-linecap:round;
	}

	.ham3 .top {
		stroke-dasharray: 40 130;
	}

	.ham3 .middle {
		stroke-dasharray: 40 140;
	}

	.ham3 .bottom {
		stroke-dasharray: 40 205;
	}

	.ham3.active .top {
		stroke-dasharray: 75 130;
		stroke-dashoffset: -63px;
	}

	.ham3.active .middle {
		stroke-dashoffset: -102px;
	}

	.ham3.active .bottom {
		stroke-dasharray: 110 205;
		stroke-dashoffset: -86px;
	}
}